import React from 'react'
import styled from 'styled-components'
import Socials from '../socials'

const Container = styled.footer`
  margin-top: 5rem;
`


const Footer = () => {
  return (
      <Container>
        <p>This website is made by BBC Studios Distribution. BBC Studios is a commercial company that is owned by the BBC (and just the BBC). No money from the licence fee was used to create this website. The profits we make from it go back to BBC programme-makers to help fund great new BBC programmes.</p>
        <p>BBC is a trademark of the British Broadcasting Corporation. Logos &copy; 1996. Terms & Conditions | Privacy Policy | Important Notice About Cookies | BBC Studios</p>
      </Container>
  )
}

export default Footer
