import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Maxwidth from '../maxwidth'
import Logo from '../../images/bbc_logo_new.svg'
import Burger from '../../images/burger.svg'

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  margin-bottom: 98px;
  img.logo {
    width: 371px;
    max-width: 66vw;
    margin-top: 29px;
  }
  img.menu-toggle {
    margin-top: 28px;
    width: 28px;
    cursor: pointer;
    display: none;
  }
`

const Nav = ({ setOpen, open }) => {
  return (
    <Maxwidth>
      <Container>
        <div></div>
        <Link to="/"><img className="logo" aria-label="BBC Studios Creative" src={Logo} alt="BBC Studios Creative" /></Link>
        <div></div>
        <img onClick={() => setOpen(true)} className="menu-toggle" src={Burger} alt="Menu" aria-haspopup="true" aria-controls="menu" />
      </Container>
    </Maxwidth>
  )
}

export default Nav
