import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  max-width: calc(1190px - 2rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
`

const Maxwidth = ({ children, nominheight, path }) => {
  return (
    <Container>{children}</Container>
  )
}

export default Maxwidth;
