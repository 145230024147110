import React from 'react'
import styled from 'styled-components'

import Facebook from '../../images/facebook_black.svg'
import Instagram from '../../images/instagram_black.svg'
import LinkedIn from '../../images/linkedin_black.svg'


const Container = styled.div`
  display: flex;
  column-gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  img {
    height: 40px;
  }
}
`

const Email = styled.p`
  margin-bottom: 4em;
  margin-top: 1em;
  text-align: center;
  a {
    color: #000;
    text-decoration: none;
  }
`

const Socials = () => {
  return (
    <>
    <Container>
      <a href="https://www.instagram.com/bbcstudioscreative" target="_blank"><img src={Instagram} alt="Instagram" /></a>
      <a href="http://www.linkedin.com/showcase/bbcstudioscreative" target="_blank"><img src={LinkedIn} alt="LinkedIn" /></a>
    </Container>
    <Email><a href="mailto:contact@bbcstudioscreative.com">contact@bbcstudioscreative.com</a></Email>
    </>
  )
}

export default Socials
