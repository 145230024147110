import React, { useState } from "react";
import { motion } from "framer-motion";
import { ReactLenis } from '@studio-freight/react-lenis'
import GlobalStyle from "../components/globalstyle";
import Maxwidth from '../components/maxwidth'
import Nav from '../components/nav'
import Footer from '../components/footer'
import Popover from '../components/popover'

export default function Layout(props) {

  const { children, path } = props;

  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <GlobalStyle />
      <Popover setOpen={setOpen} open={open} />
      <Nav setOpen={setOpen} open={open} />
      <ReactLenis root>
        <motion.div
          key={path}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}>
          {children}
        </motion.div>
      </ReactLenis>
      <Footer />
    </React.Fragment>
  );

}
