import { createGlobalStyle } from "styled-components";
import BBCSansLight_woff from "../../../static/fonts/sans-light.woff";
import BBCSansLight_woff2 from "../../../static/fonts/sans-light.woff2";
import BBCSansMedium_woff from "../../../static/fonts/sans-medium.woff";
import BBCSansMedium_woff2 from "../../../static/fonts/sans-medium.woff2";
import BBCSerifMedium_woff from "../../../static/fonts/serif-medium.woff";
import BBCSerifMedium_woff2 from "../../../static/fonts/serif-medium.woff2";

// https://fluidtypography.com/
// h1 42 -> 58 / 1.2 -> 1.1125
// h2 26 -> 36 / 1.22222 -> 1.2
// h3 18 -> 26 / "
// h4 16 -> 12 / 1.2 1.2
// p  14 -> 18 / 1.2 1.2

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  @font-face {
    font-family: "BBCSansLight";
    src: url(${BBCSansLight_woff2}) format("woff2"),
      url(${BBCSansLight_woff}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "BBCSansMedium";
    src: url(${BBCSansMedium_woff2}) format("woff2"),
      url(${BBCSansMedium_woff}) format("woff");
    font-display: swap;
  }

  @font-face {
    font-family: "BBCSerifMedium";
    src: url(${BBCSerifMedium_woff2}) format("woff2"),
      url(${BBCSerifMedium_woff}) format("woff");
    font-display: swap;
  }

  :root {
    --text: #000000;
    --orange: #F75E49;
  }

  ::-moz-selection { /* Code for Firefox */
    background: rgba(253, 233, 242, 0.75);
  }

  ::selection {
    background: rgba(253, 233, 242, 0.75);
  }

  * {
    margin: 0;
    font-family: BBCSansLight, sans-serif;
  }

  a:focus {
    outline: 0;
  }

  html {
    scroll-behavior: smooth;
  }

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

html.lenis {
  height: auto;
}

  html,
  body {
    height: 100%;
    background-color: #ffffff;
    max-width: 100vw;
  }

  body {
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    position: relative;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  #root,
  #__next {
    isolation: isolate;
  }

  main {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  h1 {
    font-family: BBCSerifMedium, serif;
    // font-size: 58px;
    // line-height: 64px;
    font-size: clamp(2.625rem, 1.848vw + 2.147rem, 3.625rem);
    line-height: clamp(3.15rem, 1.631vw + 2.728rem, 4.033rem);
    font-weight: 400;
    text-align: center;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    span {
      font-family: BBCSerifMedium, serif;
      font-size: clamp(2.625rem, 1.848vw + 2.147rem, 3.625rem);
      line-height: clamp(3.15rem, 1.631vw + 2.728rem, 4.033rem);
      font-weight: 400;
    }
  }

  h2 {
    font-family: BBCSansLight, serif;
    // font-size: 36px;
    // line-height: 44px;
    font-size: clamp(1.625rem, 1.155vw + 1.326rem, 2.25rem);
    line-height: clamp(1.95rem, 1.478vw + 1.568rem, 2.75rem);
    font-weight: 400;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 4rem;
  }

  h3 {
    font-family: BBCSansLight, serif;
    // font-size: 26px;
    // line-height: 30px;
    font-size: clamp(1.125rem, 0.924vw + 0.886rem, 1.625rem);
    line-height: clamp(1.35rem, 1.175vw + 1.046rem, 1.986rem);
    font-weight: 300;
  }

  h4 {
    font-family: BBCSansMedium, serif;
    // font-size: 16px;
    // line-height: 19.2px;
    font-size: clamp(0.75rem, 0.462vw + 0.63rem, 1rem);
    line-height: clamp(0.9rem, 0.554vw + 0.757rem, 1.2rem);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3em;
    font-weight: 400;
  }

  p {
    font-family: BBCSansLight, serif;
    // font-size: 18px;
    // line-height: 21.6px;
    font-size: clamp(0.875rem, 0.462vw + 0.755rem, 1.125rem);
    line-height: clamp(1.05rem, 0.554vw + 0.907rem, 1.35rem);
  }

  h1 span, span.orange {
    color: var(--orange);
  }

  footer {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 150px;
    padding-left: 1rem;
    padding-right: 1rem;
    p {
      margin-bottom: 1em;
      text-align: center;
    }
  }

  .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
  .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

`;

export default GlobalStyle;
