import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Maxwidth from '../maxwidth'
import Logo from '../../images/bbc_logo_new.svg'
import X from '../../images/x.svg'

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  background-color: #000;
  color: #fff;
  transition: opacity 0.25s ease-in-out;
  height: auto;
  min-height: 0;
  overflow-y: hidden;
  max-height: 0;
  opacity: 0;
  width: 100%;
  z-index: 10;
  &.open {
    position: fixed;
    min-height: 100vh;
    overflow-y: visible;
    max-height: 100vh;
    background-color: #000;
    opacity: 1;
  }
  img {
    transform: translateY(-4px);
  }
  img.logo {
    width: 371px;
    max-width: 66vw;
    margin-top: 31px;
    filter: invert(100%);
  }
  img.close {
    margin-top: 33px;
    width: 28px;
    cursor: pointer;
  }
`

const Menu = styled.nav`
  min-height: calc(100vh - 122px);
  display: flex;
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
    padding: 0;
    li {
      font-family: BBCSansLight, sans-serif;
      text-align: center;
      // font-size: 58px;
      // line-height: 68px;
      font-size: clamp(2.625rem, 1.848vw + 2.147rem, 3.625rem);
      line-height: clamp(3.15rem, 1.631vw + 2.728rem, 4.033rem);
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
`

const Popover = ({ setOpen, open }) => {

  const handleClick = (e, url) => {
    e.preventDefault();
    navigate(url);
    setTimeout(function() {
      setOpen(false);
    }, 25);
  }

  return (
    <Container className={open ? 'open' : ''}>
      <Maxwidth>
        <Flex>
          <img className="logo" src={Logo} aria-label="BBC Studios Creative" alt="BBC Studios Creative" />
          <img onClick={() => setOpen(false)} className="close" src={X} aria-label="Close" alt="" />
        </Flex>
      </Maxwidth>
      <Maxwidth>
        <Menu>
          <ul>
            <li><a href="#" onClick={(e) => handleClick(e, '/')}>Home</a></li>
            <li><a href="#" onClick={(e) => handleClick(e, '/work')}>Work</a></li>
            <li><a href="#" onClick={(e) => handleClick(e, '/branding')}>Branding</a></li>
            <li><a href="#" onClick={(e) => handleClick(e, '/digital')}>Digital</a></li>
            <li><a href="#" onClick={(e) => handleClick(e, '/campaigns')}>Campaigns</a></li>
            <li><a href="#" onClick={(e) => handleClick(e, '/about')}>About</a></li>
            <li><a href="#" onClick={(e) => handleClick(e, '/contact')}>Contact</a></li>
          </ul>
        </Menu>
      </Maxwidth>
    </Container>
  )
}

export default Popover
